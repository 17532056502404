import PhoneInput from "react-phone-input-2";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ErrorBox from "./ErrorBox";

const Home = (props) => {
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { textInfo, setTextInfo } = props;

    const verifyPhoneNumber = () => {
        console.log(textInfo.phone);
        if (textInfo.phone && textInfo.phone.match(/\d/g).length === 11) {
            console.log("VALID");
            navigate("/options");
        } else {
            console.log("FALSE");
            setError("Please enter a valid phone number.");
        }
    };

    return (
        <div className="Home pageBody d-flex flex-column justify-content-center">
            <div className="">
                <h1 className="display-4 fw-bold">Buy a round for a friend!</h1>
                <ErrorBox message={error} />
                <form action="">
                    <PhoneInput
                        containerClass="w-100 px-0 mx-auto"
                        inputClass="w-100"
                        inputStyle={{ height: "5vh" }}
                        country={"us"}
                        onlyCountries={["us"]}
                        disableDropdown={false}
                        countryCodeEditable={false}
                        regions={["america"]}
                        value={textInfo.phone}
                        onChange={(p) =>
                            setTextInfo({
                                phone: p,
                                sName: textInfo.sName,
                                rName: textInfo.rName,
                                msg: textInfo.msg,
                            })
                        }
                    />
                    <label htmlFor="inputPhone" className="form-label text-center text-white mt-1">
                        Your Friend&apos;s Mobile Number
                    </label>
                    <div className="row col-5 mx-auto">
                        <button
                            onClick={verifyPhoneNumber}
                            type="button"
                            className="btn btn-outline-light btn-lg mt-2"
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Home;
