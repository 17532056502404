import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import './card.css'
import logo from "./images/icon.jpg";


const Card = () => {
    let [card, setCard] = useState({});
    let slug = useLocation().pathname.replace("/card/", "");

    let getCard = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/get-card/${slug}`);
        let data = await res.json();

        console.log(data);
        setCard(data);
    };

    let formatNum = (n) => {

        return `${n.slice(0,4)}-${n.slice(4,8)}-${n.slice(8,12)}-${n.slice(12,16)}`
    }

    useEffect(() => {
        getCard();
    }, []);

    if (!card || card.id === undefined) {
        return <div className="Card pageBody"></div>;
    }

    return (
        <div className="Card pageBody">
            <div className="col-12 col-md-9" id="card-container">
                <div id="card" className="d-flex flex-column justify-content-between text-start">
                    <div className="row pt-3">
                        <div className="col-6 ps-3">
                            <div className="ms-3" id="chip"></div>
                        </div>
                        <div className="col-6 d-flex justify-content-end pe-3">
                            <img src={logo} className="img-fluid me-3" id="img" alt="" />
                        </div>
                    </div>
                    {/* <div className="row" id="info-container"></div> */}
                    <div className="ms-4 ps-2 pb-3" id="info-container">
                        <div className="row text-white">
                            <h2 className="fw-bold ps-0">Inu For You</h2>
                        </div>
                        <div className="row text-dark">
                            <div id="info-large">
                                <p className="ps-0 fs-4">{formatNum(card.number)}</p>
                            </div>
                        </div>
                        <div className="row text-dark mt-2">
                            <div className="ps-2 fs-4" id="info-small">
                                <p>cvc: {card.cvc}</p>
                            </div>
                            <div className="ps-2 fs-4" id="info-medium">
                                <p>exp: {card.exp_month}/{card.exp_year.toString().slice(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
