import { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link, useNavigate } from "react-router-dom";

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    // const textInfo = props.textInfo;

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const beginProcessing = async (paymentIntent) => {
        console.log("beginProcessing()")
        const textInfo = JSON.parse(window.sessionStorage.getItem("textInfo"))

        const res = await fetch(`${process.env.REACT_APP_API_URL}/processing`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ textInfo: textInfo, paymentIntent: paymentIntent }),
        });
        console.log("here")
        navigate("/cheers")
        console.log("here 2")
    };

    // const createCardReq = async () => {
    //     const res = await fetch(`${process.env.REACT_APP_API_URL}/create-card`, {
    //         method: "POST",
    //         mode: "cors",
    //         credentials: "include",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({ items: [{ card: "yes" }] }),
    //     });
    //     const card = await res.json();
    //     console.log("card", card);
    //     return card
    // };

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        console.log("clientSecret", clientSecret);
        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    setIsLoading(true)
                    beginProcessing(paymentIntent);
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Make sure to disable form submission until Stripe.js has loaded.
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const result = await stripe.confirmPayment({
            elements,
            // redirect: "if_required",
            confirmParams: {
                return_url: `${process.env.REACT_APP_CLIENT_URL}/payment`,
                // return_url: `${process.env.REACT_APP_CLIENT_URL}/payment?msg=${textInfo.msg}&r_name=${textInfo.rName}&s_name=${textInfo.sName}&phone=${textInfo.phone}`,
            },
        });
        console.log("result", result);

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (result.error) {
            if (result.error.type === "card_error" || result.error.type === "validation_error") {
                setMessage(result.error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    };

    const options = {
        mode: "payment",
        amount: 1500,
        currency: "usd",
        // Customizable with appearance API.
        appearance: {
            /*...*/
        },

        // passing the client secret obtained from the server
        /* clientSecret: sk, */
    };

    return (
        <form className="CheckoutForm" id="payment-form" onSubmit={handleSubmit}>
            <div className="col-2 mb-3">
                <Link to="/options" className="btn btn-outline-light px-4 mt-4">
                    Back
                </Link>
            </div>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <div className="row mx-auto my-3">
                {/* Show any error or success messages */}
                {message && (
                    <div id="payment-message" className="text-dark fs-4">
                        {message}
                    </div>
                )}
                <div className="col col-md-6 mx-auto">
                    <button
                        type="submit"
                        className="btn btn-outline-light btn-lg mt-4"
                        disabled={isLoading || !stripe || !elements}
                        id="submit"
                    >
                        <span id="button-text">
                            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CheckoutForm;
