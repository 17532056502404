import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState  } from "react";

import Home from "./Home";
import Options from "./Options";
import Payment from "./Payment";
import Cheers from "./Cheers";
import Card from "./Card";
import MainLogo from "./MainLogo";
import Footer from "./Footer";


const App = () => {
    let [textInfo, setTextInfo] = useState({ phone: "", sName: "", rName: "", msg: "" });

    return (
        <div className="App vh-100">
            {/* <InfoContext.Provider > */}
                <BrowserRouter>
                    <MainLogo />
                    <Routes>
                        <Route path="/" element={<Home textInfo={textInfo} setTextInfo={setTextInfo} />} />
                        <Route path="/options" element={<Options textInfo={textInfo} setTextInfo={setTextInfo} />} />
                        <Route path="/payment" element={<Payment textInfo={textInfo} />} />
                        <Route path="/cheers" element={<Cheers />} />
                        <Route path="/card/:slug" element={<Card />} />
                        <Route path="*" element={<Home textInfo={textInfo} setTextInfo={setTextInfo}/>} />
                    </Routes>
                    <Footer />
                </BrowserRouter>
            {/* </InfoContext.Provider> */}
        </div>
    );
};

export default App;
