import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ErrorBox from "./ErrorBox";

const Options = (props) => {

    const [error, setError] = useState("");

    const navigate = useNavigate();



    let verifyInfo = () => {
        if (textInfo.sName && textInfo.rName && textInfo.msg) {
            window.sessionStorage.setItem("textInfo", JSON.stringify(props.textInfo));
            navigate("/payment")
        } else {
            setError("Please fill out all fields");
        }
    };

    const { textInfo, setTextInfo } = props;
    return (
        <div className="Options pageBody px-4">
            <ErrorBox message={error} />
            {/* Nickname Input */}
            <h3 className="text-white fw-bold mt-2">Your Nickname</h3>
            <input
                className="form-control mb-2"
                type="text"
                placeholder="Your Nickname"
                value={textInfo.sName}
                onChange={(n) =>
                    setTextInfo({
                        phone: textInfo.phone,
                        sName: n.target.value,
                        rName: textInfo.rName,
                        msg: textInfo.msg,
                    })
                }
            ></input>
            {/* Recipient Input */}
            <h3 className="text-white fw-bold mt-1">Recipient&apos;s Name</h3>
            <input
                className="form-control mb-3"
                type="text"
                placeholder="Recipient's Name"
                value={textInfo.rName}
                onChange={(n) =>
                    setTextInfo({
                        phone: textInfo.phone,
                        sName: textInfo.sName,
                        rName: n.target.value,
                        msg: textInfo.msg,
                    })
                }
            ></input>
            {/* Message Input */}
            <h1 className="text-white fw-bold mt-2">Text Message</h1>
            <textarea
                rows={5}
                className="text-form form-control mb-3"
                placeholder="Type your message"
                value={textInfo.msg}
                onChange={(m) =>
                    setTextInfo({
                        phone: textInfo.phone,
                        sName: textInfo.sName,
                        rName: textInfo.rName,
                        msg: m.target.value,
                    })
                }
            ></textarea>

            {/* Back and Next buttons */}
            <div className="row mx-auto my-3">
                <div className="col">
                    <Link to="/" className="btn btn-outline-light btn-lg px-4">
                        Back
                    </Link>
                </div>
                <div className="col">
                    <button onClick={verifyInfo} type="button" className="btn btn-outline-light btn-lg px-4">
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Options;
