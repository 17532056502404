import { useContext } from "react";
import { Link } from "react-router-dom";

const Cheers = () => {
    const textInfo = JSON.parse(window.sessionStorage.getItem("textInfo"));

    return (
        <div className="Cheers pageBody">
            <div className="d-flex flex-column">
                <h1 className="display-2 fw-bold mb-4">🍺 Cheers! 🍺</h1>
                <p className="fs-1 pt-1 mb-5">
                    You sent a beer to <br />
                    <span className="fw-bold">{textInfo.rName}</span> !
                </p>
                <p className="fs-4 mt-3">Thank you for using Inu For You. Enjoy!</p>
                <Link className="nav-link col-4 mx-auto" to="/">
                    <button type="button" className="btn btn-lg btn-outline-light mx-auto bshadow">
                        &lt; Home
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Cheers;
