import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import "./stripe.css";

// const sk = process.env.REACT_APP_STRIPE_SK;
const pk = process.env.REACT_APP_STRIPE_PK;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(pk);

const Payment = (props) => {
    const [clientSecret, setClientSecret] = useState("");
    const textInfo = props.textInfo;

    const setSL = props.setSL;
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "inu" }] }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="Payment pageBody">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm setSL={setSL} textInfo={textInfo} />
                </Elements>
            )}
        </div>
    );
};

export default Payment;
