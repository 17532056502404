import logo from "./images/inu-logo.png";
import { Link } from "react-router-dom";

const MainLogo = () => {
    return (
        <div className="Logo container col-6 col-md-3 col-lg-2 px-3 pt-4 pb-2 mx-auto my-0">
            <Link to="/">
                <img className="img-fluid" src={logo} alt="" />
            </Link>
        </div>
    );
};

export default MainLogo;
