import footer from "./images/inu-footer.png";

const Footer = () => {
    return (
        <div className="Footer">
            <img src={footer} className="img-fluid h-100" alt="" />
        </div>
    );
};

export default Footer;
