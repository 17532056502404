const ErrorBox = (props) => {

    return (
        <div>
            <h1 className="lead fw-bold py-2">{props.message}</h1>
        </div>
    );
};

export default ErrorBox;
